*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Nunito Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Fira Code, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"], [multiple], textarea, select, input:where(:not([type])) {
  -webkit-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus, [multiple]:focus, textarea:focus, select:focus, input:where(:not([type])):focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::placeholder, textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  text-align: inherit;
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    -webkit-appearance: auto;
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

:root {
  --ctp-rosewater: 220, 138, 120;
  --ctp-flamingo: 221, 120, 120;
  --ctp-pink: 234, 118, 203;
  --ctp-mauve: 136, 57, 239;
  --ctp-red: 210, 15, 57;
  --ctp-maroon: 230, 69, 83;
  --ctp-peach: 254, 100, 11;
  --ctp-yellow: 223, 142, 29;
  --ctp-green: 64, 160, 43;
  --ctp-teal: 23, 146, 153;
  --ctp-sky: 4, 165, 229;
  --ctp-sapphire: 32, 159, 181;
  --ctp-blue: 30, 102, 245;
  --ctp-lavender: 114, 135, 253;
  --ctp-text: 76, 79, 105;
  --ctp-subtext1: 92, 95, 119;
  --ctp-subtext0: 108, 111, 133;
  --ctp-overlay2: 124, 127, 147;
  --ctp-overlay1: 140, 143, 161;
  --ctp-overlay0: 156, 160, 176;
  --ctp-surface2: 172, 176, 190;
  --ctp-surface1: 188, 192, 204;
  --ctp-surface0: 204, 208, 218;
  --ctp-base: 239, 241, 245;
  --ctp-mantle: 230, 233, 239;
  --ctp-crust: 220, 224, 232;
}

.cat-mocha {
  --ctp-rosewater: 245, 224, 220;
  --ctp-flamingo: 242, 205, 205;
  --ctp-pink: 245, 194, 231;
  --ctp-mauve: 203, 166, 247;
  --ctp-red: 243, 139, 168;
  --ctp-maroon: 235, 160, 172;
  --ctp-peach: 250, 179, 135;
  --ctp-yellow: 249, 226, 175;
  --ctp-green: 166, 227, 161;
  --ctp-teal: 148, 226, 213;
  --ctp-sky: 137, 220, 235;
  --ctp-sapphire: 116, 199, 236;
  --ctp-blue: 137, 180, 250;
  --ctp-lavender: 180, 190, 254;
  --ctp-text: 205, 214, 244;
  --ctp-subtext1: 186, 194, 222;
  --ctp-subtext0: 166, 173, 200;
  --ctp-overlay2: 147, 153, 178;
  --ctp-overlay1: 127, 132, 156;
  --ctp-overlay0: 108, 112, 134;
  --ctp-surface2: 88, 91, 112;
  --ctp-surface1: 69, 71, 90;
  --ctp-surface0: 49, 50, 68;
  --ctp-base: 30, 30, 46;
  --ctp-mantle: 24, 24, 37;
  --ctp-crust: 17, 17, 27;
}

html, body {
  text-rendering: optimizelegibility;
}

a {
  transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

a:link {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-blue), var(--tw-text-opacity));
}

a:visited {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-lavender), var(--tw-text-opacity));
}

a:any-link:-webkit-any(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

a:any-link:is(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

a:any-link:focus-visible {
  outline-offset: 1px;
  border-radius: .125rem;
  outline: 2px solid;
  transition-duration: .15s;
}

a:any-link:active {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sky), var(--tw-text-opacity));
  transition-duration: 75ms;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Red Rose, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
}

label {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext1), var(--tw-text-opacity));
  font-family: Josefin Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 600;
}

button, [type="button"], [type="reset"], [type="submit"] {
  cursor: pointer;
  border-radius: .5rem;
  align-items: center;
  gap: .25rem;
  padding: .625rem .75rem;
  display: flex;
  position: relative;
}

button, button *, [type="button"], [type="button"] *, [type="reset"], [type="reset"] *, [type="submit"], [type="submit"] * {
  transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

button > .material-symbols-outlined, [type="button"] > .material-symbols-outlined, [type="reset"] > .material-symbols-outlined, [type="submit"] > .material-symbols-outlined {
  font-variation-settings: "opsz" 18;
  font-size: 1.125rem;
  line-height: 1;
  position: relative;
  top: -.0625cap;
}

button > .label, [type="button"] > .label, [type="reset"] > .label, [type="submit"] > .label {
  font-family: Josefin Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-variant-caps: small-caps;
  font-weight: 600;
  line-height: 1;
}

button[disabled], [type="button"][disabled], [type="reset"][disabled], [type="submit"][disabled] {
  cursor: default;
  opacity: .3;
}

[type="reset"] {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-flamingo), var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-base), var(--tw-text-opacity));
}

[type="reset"]:not([disabled]):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-pink), var(--tw-bg-opacity));
}

[type="reset"]:not([disabled]):active {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-pink), var(--tw-bg-opacity));
  transition-duration: 75ms;
}

[type="submit"] {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-blue), var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-base), var(--tw-text-opacity));
}

[type="submit"]:not([disabled]):hover {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-sapphire), var(--tw-bg-opacity));
}

[type="submit"]:not([disabled]):active {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-sky), var(--tw-bg-opacity));
  transition-duration: 75ms;
}

[type="checkbox"], [type="color"], [type="date"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="radio"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], select, textarea {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(var(--ctp-overlay1), var(--tw-border-opacity));
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgb(var(--ctp-surface1));
  background-color: inherit;
  color: inherit;
  transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

[type="checkbox"][disabled], [type="color"][disabled], [type="date"][disabled], [type="datetime-local"][disabled], [type="email"][disabled], [type="month"][disabled], [type="number"][disabled], [type="password"][disabled], [type="radio"][disabled], [type="search"][disabled], [type="tel"][disabled], [type="text"][disabled], [type="time"][disabled], [type="url"][disabled], [type="week"][disabled], select[disabled], textarea[disabled] {
  opacity: .7;
}

[type="checkbox"], [type="color"], [type="date"], [type="datetime-local"], [type="email"], [type="month"], [type="number"], [type="password"], [type="radio"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], select, textarea {
  border-radius: .375rem;
}

[type="email"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="url"], textarea {
  font-family: Fira Code, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: .875rem;
  line-height: 1.25rem;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-overlay1), var(--tw-text-opacity));
}

[type="checkbox"]:invalid, [type="color"]:invalid, [type="date"]:invalid, [type="datetime-local"]:invalid, [type="email"]:invalid, [type="month"]:invalid, [type="number"]:invalid, [type="password"]:invalid, [type="radio"]:invalid, [type="search"]:invalid, [type="tel"]:invalid, [type="text"]:invalid, [type="time"]:invalid, [type="url"]:invalid, [type="week"]:invalid, select:invalid, textarea:invalid {
  outline-offset: -1px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-red));
}

button:focus-visible, [type="button"]:focus-visible, [type="reset"]:focus-visible, [type="submit"]:focus-visible, [type="checkbox"]:focus-visible, [type="color"]:focus-visible, [type="date"]:focus-visible, [type="datetime-local"]:focus-visible, [type="email"]:focus-visible, [type="month"]:focus-visible, [type="number"]:focus-visible, [type="password"]:focus-visible, [type="radio"]:focus-visible, [type="search"]:focus-visible, [type="tel"]:focus-visible, [type="text"]:focus-visible, [type="time"]:focus-visible, [type="url"]:focus-visible, [type="week"]:focus-visible, select:focus-visible, textarea:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sapphire));
  transition-duration: .15s;
}

button:focus-visible, [type="button"]:focus-visible, [type="reset"]:focus-visible, [type="submit"]:focus-visible {
  outline-offset: 2px;
}

[type="checkbox"]:focus-visible, [type="color"]:focus-visible, [type="date"]:focus-visible, [type="datetime-local"]:focus-visible, [type="email"]:focus-visible, [type="month"]:focus-visible, [type="number"]:focus-visible, [type="password"]:focus-visible, [type="radio"]:focus-visible, [type="search"]:focus-visible, [type="tel"]:focus-visible, [type="text"]:focus-visible, [type="time"]:focus-visible, [type="url"]:focus-visible, [type="week"]:focus-visible, select:focus-visible, textarea:focus-visible {
  outline-offset: -1px;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.fa-icon > svg {
  fill: currentColor;
  stroke: currentColor;
  width: 1.5rem;
  height: 1.5rem;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 500, "GRAD" 0, "opsz" 40;
  -webkit-user-select: none;
  user-select: none;
  font-variant-caps: normal;
  transition-property: font-variation-settings;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tooltip, .tooltip-caret {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: 0;
  pointer-events: none;
  font-variant-caps: normal;
  transition: opacity .3s cubic-bezier(.4, 0, .2, 1) .5s;
  position: absolute;
  left: 50%;
}

:-webkit-any(:hover, :focus-visible) > .tooltip {
  opacity: 1;
}

:is(:hover, :focus-visible) > .tooltip {
  opacity: 1;
}

:-webkit-any(:hover, :focus-visible) > .tooltip-caret {
  opacity: 1;
}

:is(:hover, :focus-visible) > .tooltip-caret {
  opacity: 1;
}

.tooltip {
  z-index: 51;
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgb(var(--ctp-surface1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--ctp-teal), var(--tw-ring-opacity));
  --tw-ring-offset-width: 1px;
  --tw-ring-offset-color: rgb(var(--ctp-surface1));
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-mantle), var(--tw-bg-opacity));
  color: rgba(var(--ctp-subtext1), var(--tw-text-opacity));
  --tw-text-opacity: 1;
  border-radius: .375rem;
  padding: .25rem .5rem;
  font-family: Josefin Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

.tooltip:not(.below) {
  bottom: 100%;
}

.tooltip.below {
  top: 100%;
}

.tooltip-caret {
  z-index: 50;
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-teal), var(--tw-bg-opacity));
  border-radius: .125rem;
  width: .75rem;
  height: .75rem;
}

.tooltip:not(.below) + .tooltip-caret {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  bottom: 100%;
}

.tooltip.below + .tooltip-caret {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  top: 100%;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.theme-dark .material-symbols-outlined {
  font-variation-settings: "GRAD" -25;
}

.theme-light, .theme-system {
  font-palette: light;
}

@media (prefers-color-scheme: dark) {
  .theme-system .material-symbols-outlined {
    font-variation-settings: "GRAD" -25;
  }
}

@property --highlight {
  syntax: "<integer>#";
  inherits: true;
  initial-value: 0, 0, 0;
}

@property --main-px {
  syntax: "<length>";
  inherits: true;
  initial-value: 1rem;
}

@property --main-py {
  syntax: "<length>";
  inherits: true;
  initial-value: .75rem;
}

@property --main-p-mx {
  syntax: "<length>";
  inherits: true;
  initial-value: .5rem;
}

@property --main-p-my {
  syntax: "<length>";
  inherits: true;
  initial-value: 1rem;
}

@property --page-header-height {
  syntax: "<length>";
  inherits: true;
  initial-value: 2rem;
}

@property --page-header-mx {
  syntax: "<length>";
  inherits: true;
  initial-value: 1.5rem;
}

@property --page-header-my {
  syntax: "<length>";
  inherits: true;
  initial-value: .75rem;
}

:root {
  --highlight: var(--ctp-sapphire);
  --main-px: 1rem;
  --main-py: .75rem;
}

@media (width >= 375px) {
  :root {
    --main-px: 1.5rem;
  }
}

@media (width >= 640px) {
  :root {
    --main-px: 2rem;
    --main-py: 1rem;
  }
}

@media (width >= 768px) {
  :root {
    --main-px: 3rem;
    --main-py: 1.5rem;
  }
}

@media (width >= 1024px) {
  :root {
    --main-px: 4rem;
    --main-py: 2rem;
  }
}

@media (width >= 1280px) {
  :root {
    --main-px: 5rem;
    --main-py: 2.5rem;
  }
}

@media (width >= 1536px) {
  :root {
    --main-px: 6rem;
    --main-py: 3rem;
  }
}

:root {
  --main-p-mx: .5rem;
  --main-p-my: 1rem;
}

@media (width >= 375px) {
  :root {
    --main-p-mx: 1rem;
  }
}

@media (width >= 768px) {
  :root {
    --main-p-mx: 1.5rem;
  }
}

@media (width >= 1024px) {
  :root {
    --main-p-mx: 2rem;
  }
}

@media (width >= 1280px) {
  :root {
    --main-p-mx: 2.5rem;
  }
}

@media (width >= 1536px) {
  :root {
    --main-p-mx: 3rem;
  }
}

:root {
  --page-header-height: 2rem;
  --page-header-mx: 1.5rem;
  --page-header-my: .75rem;
}

@media (width >= 1024px) {
  :root {
    --page-header-height: 2.5rem;
    --page-header-mx: 2rem;
    --page-header-my: 1rem;
  }
}

@media (width >= 1280px) {
  :root {
    --page-header-height: 2.75rem;
    --page-header-mx: 2.5rem;
    --page-header-my: 1.25rem;
  }
}

@media (width >= 1536px) {
  :root {
    --page-header-height: 3rem;
    --page-header-mx: 3rem;
    --page-header-my: 1.5rem;
  }
}

:root {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light dark;
}

@media (prefers-color-scheme: dark) {
  :root {
    --lightningcss-light: ;
    --lightningcss-dark: initial;
  }
}

.page {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-crust), var(--tw-bg-opacity));
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.page ::selection, .page::selection {
  background-color: rgba(var(--ctp-surface2), .5);
}

.page {
  padding-top: calc(var(--page-header-height)  + 2 * var(--page-header-my));
  accent-color: rgb(var(--ctp-sapphire));
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-text), var(--tw-text-opacity));
  flex-direction: column;
  font-family: Vollkorn, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  display: flex;
}

.skip-link {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 0;
  left: 50%;
}

.skip-link:focus {
  --tw-translate-y: calc(var(--page-header-height)  + 2 * var(--page-header-my));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.site-badge {
  top: calc(var(--page-header-my)  + .25rem);
  z-index: 20;
  margin-left: var(--page-header-mx);
  height: calc(var(--page-header-height)  - var(--page-header-my) / 2);
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  border-radius: 9999px;
  width: fit-content;
  position: fixed;
}

@media (width >= 1024px) {
  .site-badge {
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  }
}

.site-badge {
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgb(var(--ctp-surface1));
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--ctp-surface1), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgba(var(--ctp-mantle), .6);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--shadow), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-base), var(--tw-bg-opacity));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.site-badge * {
  -webkit-user-select: none;
  user-select: none;
}

.site-badge .logo {
  z-index: 21;
  box-sizing: content-box;
  height: var(--page-header-height);
  width: var(--page-header-height);
  --shadow: -8px 10px 15px -3px rgb(var(--ctp-surface0), .6), -4px 4px 6px -4px rgb(var(--ctp-surface0), .6);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--ctp-surface1), var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: rgba(var(--ctp-mantle), .6);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--shadow), var(--tw-shadow);
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(var(--ctp-overlay1), var(--tw-border-opacity));
  border-radius: 9999px;
}

.site-badge .title {
  display: none;
}

@media (width >= 768px) {
  .site-badge .title {
    display: block;
  }
}

.site-badge .title {
  margin-right: .5rem;
}

@media (width >= 1024px) {
  .site-badge .title {
    margin-right: .75rem;
  }
}

@media (width >= 1536px) {
  .site-badge .title {
    margin-right: 1rem;
  }
}

.site-badge .title {
  background: text linear-gradient(to bottom right, rgb(var(--ctp-sapphire)), rgb(var(--ctp-lavender)));
  color: #0000;
}

@media (width >= 1024px) {
  .site-badge .title {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1536px) {
  .site-badge .title {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.site-badge .title {
  font-family: Fira Code, ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-weight: 600;
}

.site-badge > a {
  align-items: center;
  gap: .5rem;
  height: 100%;
  display: flex;
}

.site-badge > a:focus-visible {
  border-radius: 9999px;
}

.site-badge > a:focus-visible .logo {
  outline: 2px solid;
  transition-property: outline;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu-wrapper {
  right: var(--page-header-mx);
  top: var(--page-header-my);
  position: fixed;
}

.menu-wrapper:has( > .menu.open) {
  z-index: 30;
}

.menu-wrapper:has( > .menu.open) #menu-tooltip, .menu-wrapper:has( > .menu.open) #menu-tooltip + .tooltip-caret {
  opacity: 0;
  transition-duration: 0s;
  transition-delay: 0s;
}

@media (width >= 640px) {
  .menu-wrapper {
    right: unset;
    z-index: 10;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
  }

  .menu-wrapper #menu-tooltip, .menu-wrapper #menu-tooltip + .tooltip-caret {
    opacity: 0;
    transition-duration: 0s;
    transition-delay: 0s;
  }
}

.menu {
  height: var(--page-header-height);
  width: var(--page-header-height);
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  border-radius: 9999px;
  position: relative;
}

@media (width >= 1024px) {
  .menu {
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  }
}

.menu {
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgb(var(--ctp-surface1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--ctp-surface1), var(--tw-ring-opacity));
  --tw-ring-offset-width: 3px;
  --tw-ring-offset-color: rgba(var(--ctp-mantle), .6);
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-base), var(--tw-bg-opacity));
}

.menu * {
  transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu > header > button {
  height: var(--page-header-height);
  max-height: var(--page-header-height);
  width: var(--page-header-height);
  max-width: var(--page-header-height);
  background-color: inherit;
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  box-shadow: var(--shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-style: none;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.menu > header > button > .highlight {
  height: var(--page-header-height);
  width: var(--page-header-height);
  opacity: 0;
  background: radial-gradient(closest-side, transparent 70%, rgb(var(--highlight), .5) 90%, rgb(var(--highlight)));
  pointer-events: none;
  border-radius: 9999px;
  position: absolute;
}

.menu > header > button .material-symbols-outlined {
  font-size: 24px;
  position: static;
}

@media (width >= 1024px) {
  .menu > header > button .material-symbols-outlined {
    font-size: 30px;
  }
}

@media (width >= 1280px) {
  .menu > header > button .material-symbols-outlined {
    font-size: 32px;
  }
}

@media (width >= 1536px) {
  .menu > header > button .material-symbols-outlined {
    font-size: 36px;
  }
}

.menu > header > button .material-symbols-outlined {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext0), var(--tw-text-opacity));
  font-variation-settings: "opsz" 24;
}

@media (width >= 1024px) {
  .menu > header > button .material-symbols-outlined {
    font-variation-settings: "opsz" 30;
  }
}

@media (width >= 1280px) {
  .menu > header > button .material-symbols-outlined {
    font-variation-settings: "opsz" 32;
  }
}

@media (width >= 1536px) {
  .menu > header > button .material-symbols-outlined {
    font-variation-settings: "opsz" 36;
  }
}

.menu > header > button:hover > .highlight {
  opacity: 1;
}

.menu > header > button:-webkit-any(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.menu > header > button:is(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.menu > header > button:-webkit-any(:hover, :focus-visible) :not(.tooltip) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.menu > header > button:is(:hover, :focus-visible) :not(.tooltip) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.menu > header > button:focus-visible {
  outline-offset: -2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sapphire));
  transition-duration: .15s;
}

.menu > header > button:focus-visible :not(.tooltip) {
  transition-duration: .15s;
}

.menu > header > button:active {
  --highlight: var(--ctp-sky);
  outline-offset: -2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sky));
}

.menu > header > button:active, .menu > header > button:active :not(.tooltip) {
  transition-duration: 75ms;
}

.menu > header > button:active .material-symbols-outlined {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sky), var(--tw-text-opacity));
}

.menu > header > .heading-wrapper, .menu > ul {
  display: none;
}

.menu.open {
  --tw-translate-y: -.25rem;
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: .375rem;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  height: auto;
  display: flex;
}

.menu.open > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-style: double;
  border-color: rgba(var(--ctp-surface0), var(--tw-divide-opacity));
}

.menu.open > header {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-mantle), var(--tw-bg-opacity));
  flex-direction: row-reverse;
  align-items: stretch;
  display: flex;
}

.menu.open > header > button {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin: .25rem;
}

.menu.open > header > button:-webkit-any(:hover, :focus-visible, :active) {
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  box-shadow: var(--shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.menu.open > header > button:is(:hover, :focus-visible, :active) {
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  box-shadow: var(--shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.menu.open > header > .heading-wrapper {
  flex-grow: 1;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.menu.open > header > .heading-wrapper > h2 {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext0), var(--tw-text-opacity));
  text-shadow: 1px 2px 2px rgb(var(--ctp-text), .2);
  flex-grow: 1;
  margin: 0;
  font-family: Oxanium, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
}

.menu.open > ul {
  opacity: 1;
  flex-direction: column;
  display: flex;
}

.menu.open > ul > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  border-style: solid;
  border-color: rgba(var(--ctp-surface1), .3);
}

.menu.open > ul > li {
  width: 100%;
  height: 2.25rem;
}

.menu.open > ul > li:first-child {
  height: calc(2.25rem - 2px);
}

.menu.open > ul > li:last-child, .menu.open > ul > li:last-child > .wrapper, .menu.open > ul > li:last-child > .wrapper > .highlight {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.menu.open > ul > li * {
  transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu.open > ul > li > .wrapper {
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.menu.open > ul > li > .wrapper > .highlight {
  opacity: 0;
  background: linear-gradient(to right, rgb(var(--highlight)), rgb(var(--highlight), .5) 3px, rgb(var(--highlight), .2) .25rem, transparent 1rem calc(100% - 1rem), rgb(var(--highlight), .2) calc(100% - .25rem), rgb(var(--highlight), .5) calc(100% - 3px), rgb(var(--highlight)));
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.menu.open > ul > li > .wrapper > a {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext1), var(--tw-text-opacity));
  width: 100%;
  padding: .25rem 1.5rem;
  font-family: Josefin Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}

.menu.open > ul > li > .wrapper > a:-webkit-any(:focus-visible, :active) {
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition-duration: 0s;
}

.menu.open > ul > li > .wrapper > a:is(:focus-visible, :active) {
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition-duration: 0s;
}

.menu.open > ul > li > .wrapper:has( > a:focus-visible) {
  outline-offset: -2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sapphire));
  transition-duration: .15s;
}

.menu.open > ul > li > .wrapper:has( > a:focus-visible) * {
  transition-duration: .15s;
}

.menu.open > ul > li > .wrapper:has( > a:focus-visible:active) {
  outline-color: rgb(var(--ctp-sky));
  transition-duration: 75ms;
}

.menu.open > ul > li > .wrapper:has( > a:focus-visible:active) * {
  transition-duration: 75ms;
}

.menu.open > ul > li > .wrapper:has( > a[aria-current="page"]) {
  background: padding-box linear-gradient(to right, rgb(var(--ctp-lavender)), rgb(var(--ctp-lavender), .5) 3px, rgb(var(--ctp-lavender), .2) .4rem, transparent 1rem calc(100% - 1rem), rgb(var(--ctp-lavender), .2) calc(100% - .4rem), rgb(var(--ctp-lavender), .5) calc(100% - 3px), rgb(var(--ctp-lavender)));
}

.menu.open > ul > li > .wrapper:has( > a[aria-current="page"]) > a {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-lavender), var(--tw-text-opacity));
}

.menu.open > ul > li > .wrapper:not(:has( > a[aria-current="page"])):has( > a:hover) > .highlight {
  opacity: 1;
}

.menu.open > ul > li > .wrapper:not(:has( > a[aria-current="page"])):has( > a:active) {
  --highlight: var(--ctp-sky);
}

.menu.open > ul > li > .wrapper:not(:has( > a[aria-current="page"])) > a:-webkit-any(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.menu.open > ul > li > .wrapper:not(:has( > a[aria-current="page"])) > a:is(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.menu.open > ul > li > .wrapper:not(:has( > a[aria-current="page"])) > a:active {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sky), var(--tw-text-opacity));
}

@media (width >= 640px) {
  .menu {
    width: fit-content;
    font-size: .875rem;
    line-height: 1.25rem;
    position: static;
  }

  @media (width >= 1024px) {
    .menu {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  @media (width >= 1536px) {
    .menu {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  .menu {
    --tw-text-opacity: 1;
    color: rgba(var(--ctp-subtext0), var(--tw-text-opacity));
    -webkit-user-select: none;
    user-select: none;
    font-family: Oxanium, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-weight: 600;
  }

  .menu > header {
    display: none;
  }

  .menu > ul {
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
  }

  .menu > ul > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
    border-style: double;
    border-color: rgba(var(--ctp-surface1), .3);
  }

  .menu > ul > li {
    height: 100%;
  }

  .menu > ul > li * {
    transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .menu > ul > li:first-child, .menu > ul > li:first-child > .wrapper {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .menu > ul > li:first-child > .wrapper > :-webkit-any(.highlight, a) {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .menu > ul > li:first-child > .wrapper > :is(.highlight, a) {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .menu > ul > li:first-child > .wrapper > a {
    padding-left: 1.25rem;
  }

  @media (width >= 1024px) {
    .menu > ul > li:first-child > .wrapper > a {
      padding-left: 1.5rem;
    }
  }

  @media (width >= 1536px) {
    .menu > ul > li:first-child > .wrapper > a {
      padding-left: 1.75rem;
    }
  }

  .menu > ul > li:last-child, .menu > ul > li:last-child > .wrapper {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .menu > ul > li:last-child > .wrapper > :-webkit-any(.highlight, a) {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .menu > ul > li:last-child > .wrapper > :is(.highlight, a) {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .menu > ul > li:last-child > .wrapper > a {
    padding-right: 1.25rem;
  }

  @media (width >= 1024px) {
    .menu > ul > li:last-child > .wrapper > a {
      padding-right: 1.5rem;
    }
  }

  @media (width >= 1536px) {
    .menu > ul > li:last-child > .wrapper > a {
      padding-right: 1.75rem;
    }
  }

  .menu > ul > li > .wrapper {
    height: 100%;
  }

  .menu > ul > li > .wrapper > .highlight {
    opacity: 0;
    background: linear-gradient(to bottom, rgb(var(--highlight)), rgb(var(--highlight), .5) 2px, rgb(var(--highlight), .2) 10%, transparent 25% 75%, rgb(var(--highlight), .2) 90%, rgb(var(--highlight), .5) calc(100% - 2px), rgb(var(--highlight)));
    height: 100%;
  }

  .menu > ul > li > .wrapper > a {
    width: 100%;
    height: 100%;
    padding-left: .75rem;
    padding-right: .75rem;
    position: relative;
    top: -100%;
  }

  @media (width >= 1024px) {
    .menu > ul > li > .wrapper > a {
      padding: .5rem 1rem;
    }
  }

  @media (width >= 1536px) {
    .menu > ul > li > .wrapper > a {
      padding: 1rem 1.5rem;
    }
  }

  .menu > ul > li > .wrapper > a {
    --tw-text-opacity: 1;
    color: rgba(var(--ctp-subtext0), var(--tw-text-opacity));
    align-items: center;
    display: flex;
  }

  .menu > ul > li > .wrapper > a:focus-visible {
    outline-offset: -2px;
    outline: 2px solid #0000;
    outline-color: rgb(var(--ctp-sapphire));
    transition-duration: .15s;
  }

  .menu > ul > li > .wrapper > a:focus-visible:active {
    outline-color: rgb(var(--ctp-sky));
    transition-duration: 75ms;
  }

  .menu > ul > li > .wrapper:has( > a[aria-current="page"]) {
    background: padding-box linear-gradient(to bottom, rgb(var(--ctp-lavender)), rgb(var(--ctp-lavender), .5) 2px, rgb(var(--ctp-lavender), .2) 10%, transparent 25% 75%, rgb(var(--ctp-lavender), .2) 90%, rgb(var(--ctp-lavender), .5) calc(100% - 2px), rgb(var(--ctp-lavender)));
  }

  .menu > ul > li > .wrapper:has( > a[aria-current="page"]) > a {
    --tw-text-opacity: 1;
    color: rgba(var(--ctp-lavender), var(--tw-text-opacity));
  }

  .menu > ul > li > .wrapper:not(:has( > a[aria-current="page"])):has( > a:hover) > .highlight {
    opacity: 1;
  }

  .menu > ul > li > .wrapper:not(:has( > a[aria-current="page"])):has( > a:active) {
    --highlight: var(--ctp-sky);
  }

  .menu > ul > li > .wrapper:not(:has( > a[aria-current="page"])) > a:-webkit-any(:hover, :focus-visible) {
    --tw-text-opacity: 1;
    color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
  }

  .menu > ul > li > .wrapper:not(:has( > a[aria-current="page"])) > a:is(:hover, :focus-visible) {
    --tw-text-opacity: 1;
    color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
  }

  .menu > ul > li > .wrapper:not(:has( > a[aria-current="page"])) > a:active {
    --tw-text-opacity: 1;
    color: rgba(var(--ctp-sky), var(--tw-text-opacity));
  }
}

.theme-switcher {
  right: calc(1.5 * var(--page-header-mx)  + var(--page-header-height));
  top: var(--page-header-my);
  z-index: 20;
  position: absolute;
}

@media (width >= 640px) {
  .theme-switcher {
    right: var(--page-header-mx);
  }
}

.theme-switcher {
  height: var(--page-header-height);
  width: var(--page-header-height);
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  border-radius: 9999px;
}

@media (width >= 1024px) {
  .theme-switcher {
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  }
}

.theme-switcher {
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgb(var(--ctp-surface1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--ctp-surface1), var(--tw-ring-opacity));
  --tw-ring-offset-width: 3px;
  --tw-ring-offset-color: rgba(var(--ctp-mantle), .6);
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-base), var(--tw-bg-opacity));
  -webkit-user-select: none;
  user-select: none;
}

.theme-switcher * {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.theme-switcher > header > button {
  height: var(--page-header-height);
  max-height: var(--page-header-height);
  width: var(--page-header-height);
  max-width: var(--page-header-height);
  background-color: inherit;
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  box-shadow: var(--shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  cursor: pointer;
  border-style: none;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.theme-switcher > header > button > .highlight {
  height: var(--page-header-height);
  width: var(--page-header-height);
  opacity: 0;
  background: radial-gradient(closest-side, transparent 70%, rgb(var(--highlight), .5) 90%, rgb(var(--highlight)));
  pointer-events: none;
  border-radius: 9999px;
  position: absolute;
}

.theme-switcher > header > button .material-symbols-outlined {
  font-size: 24px;
  position: static;
}

@media (width >= 1024px) {
  .theme-switcher > header > button .material-symbols-outlined {
    font-size: 30px;
  }
}

@media (width >= 1280px) {
  .theme-switcher > header > button .material-symbols-outlined {
    font-size: 32px;
  }
}

@media (width >= 1536px) {
  .theme-switcher > header > button .material-symbols-outlined {
    font-size: 36px;
  }
}

.theme-switcher > header > button .material-symbols-outlined {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext0), var(--tw-text-opacity));
  font-variation-settings: "opsz" 24;
}

@media (width >= 1024px) {
  .theme-switcher > header > button .material-symbols-outlined {
    font-variation-settings: "opsz" 30;
  }
}

@media (width >= 1280px) {
  .theme-switcher > header > button .material-symbols-outlined {
    font-variation-settings: "opsz" 32;
  }
}

@media (width >= 1536px) {
  .theme-switcher > header > button .material-symbols-outlined {
    font-variation-settings: "opsz" 36;
  }
}

.theme-switcher > header > button:hover > .highlight {
  opacity: 1;
}

.theme-switcher > header > button:-webkit-any(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher > header > button:is(:hover, :focus-visible) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher > header > button:-webkit-any(:hover, :focus-visible) :not(.tooltip) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher > header > button:is(:hover, :focus-visible) :not(.tooltip) {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher > header > button:focus-visible {
  outline-offset: -2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sapphire));
  transition-duration: .15s;
}

.theme-switcher > header > button:focus-visible :not(.tooltip) {
  transition-duration: .15s;
}

.theme-switcher > header > button:active {
  --highlight: var(--ctp-sky);
  outline-offset: -2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sky));
}

.theme-switcher > header > button:active, .theme-switcher > header > button:active :not(.tooltip) {
  transition-duration: 75ms;
}

.theme-switcher > header > button:active .material-symbols-outlined {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sky), var(--tw-text-opacity));
}

.theme-switcher > header > .heading-wrapper, .theme-switcher > menu {
  display: none;
}

.theme-switcher.open {
  z-index: 30;
  --tw-translate-y: -.25rem;
  --tw-translate-x: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: .375rem;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  height: auto;
  display: flex;
}

.theme-switcher.open > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-style: double;
  border-color: rgba(var(--ctp-surface0), var(--tw-divide-opacity));
}

.theme-switcher.open > header {
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-mantle), var(--tw-bg-opacity));
  flex-direction: row-reverse;
  align-items: stretch;
  display: flex;
}

.theme-switcher.open > header > button {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin: .25rem;
}

.theme-switcher.open > header > button:-webkit-any(:hover, :focus-visible, :active) {
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  box-shadow: var(--shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.theme-switcher.open > header > button:is(:hover, :focus-visible, :active) {
  --shadow: inset 0 0 4px 2px rgb(var(--ctp-surface0), .6);
  box-shadow: var(--shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.theme-switcher.open > header > .heading-wrapper {
  flex-grow: 1;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.theme-switcher.open > header > .heading-wrapper > h2 {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext0), var(--tw-text-opacity));
  text-shadow: 1px 2px 2px rgb(var(--ctp-text), .2);
  flex-grow: 1;
  margin: 0;
  font-family: Oxanium, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
}

.theme-switcher.open > menu {
  opacity: 1;
  flex-direction: column;
  display: flex;
}

.theme-switcher.open > menu > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  border-style: solid;
  border-color: rgba(var(--ctp-surface1), .3);
}

.theme-switcher.open > menu > li {
  width: 100%;
  height: 2.25rem;
}

.theme-switcher.open > menu > li:first-child {
  height: calc(2.25rem - 2px);
}

.theme-switcher.open > menu > li:last-child, .theme-switcher.open > menu > li:last-child > .wrapper, .theme-switcher.open > menu > li:last-child > .wrapper > .highlight {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.theme-switcher.open > menu > li * {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.theme-switcher.open > menu > li > .wrapper {
  cursor: pointer;
  align-items: center;
  width: 100%;
  height: 100%;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.theme-switcher.open > menu > li > .wrapper > .highlight {
  opacity: 0;
  background: linear-gradient(to right, rgb(var(--highlight)), rgb(var(--highlight), .5) 3px, rgb(var(--highlight), .2) .4rem, transparent 1rem calc(100% - 1rem), rgb(var(--highlight), .2) calc(100% - .4rem), rgb(var(--highlight), .5) calc(100% - 3px), rgb(var(--highlight)));
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.theme-switcher.open > menu > li > .wrapper > .label-wrapper {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext1), var(--tw-text-opacity));
  align-items: baseline;
  gap: .5rem;
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 1rem;
  line-height: 1;
  display: flex;
}

.theme-switcher.open > menu > li > .wrapper > .label-wrapper .material-symbols-outlined {
  font-variation-settings: "opsz" 14;
  font-size: .875rem;
  line-height: 1;
  position: relative;
  top: .125cap;
}

.theme-switcher.open > menu > li > .wrapper > .label-wrapper .label {
  font-family: Josefin Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 400;
}

.theme-switcher.open > menu > li:focus-visible {
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition-duration: 0s;
}

.theme-switcher.open > menu > li:focus-visible > .wrapper {
  outline-offset: -2px;
  outline: 2px solid #0000;
  outline-color: rgb(var(--ctp-sapphire));
  transition-duration: .15s;
}

.theme-switcher.open > menu > li:focus-visible > .wrapper * {
  transition-duration: .15s;
}

.theme-switcher.open > menu > li:focus-visible > .wrapper:active {
  outline-color: rgb(var(--ctp-sky));
  transition-duration: 75ms;
}

.theme-switcher.open > menu > li:focus-visible > .wrapper:active * {
  transition-duration: 75ms;
}

.theme-switcher.open > menu > li[aria-checked="true"] > .wrapper {
  background: padding-box linear-gradient(to right, rgb(var(--ctp-lavender)), rgb(var(--ctp-lavender), .5) 3px, rgb(var(--ctp-lavender), .2) .4rem, transparent 1rem calc(100% - 1rem), rgb(var(--ctp-lavender), .2) calc(100% - .4rem), rgb(var(--ctp-lavender), .5) calc(100% - 3px), rgb(var(--ctp-lavender)));
}

.theme-switcher.open > menu > li[aria-checked="true"] > .wrapper .material-symbols-outlined, .theme-switcher.open > menu > li[aria-checked="true"] > .wrapper .label {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-lavender), var(--tw-text-opacity));
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):hover > .wrapper > .highlight {
  opacity: 1;
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):active {
  --highlight: var(--ctp-sky);
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):-webkit-any(:hover, :focus-visible) .material-symbols-outlined {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):is(:hover, :focus-visible) .material-symbols-outlined {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):-webkit-any(:hover, :focus-visible) .label {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):is(:hover, :focus-visible) .label {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sapphire), var(--tw-text-opacity));
}

.theme-switcher.open > menu > li:not([aria-checked="true"]):active .material-symbols-outlined, .theme-switcher.open > menu > li:not([aria-checked="true"]):active .label {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-sky), var(--tw-text-opacity));
}

.theme-switcher.open #theme-switcher-tooltip, .theme-switcher.open #theme-switcher-tooltip + .tooltip-caret {
  opacity: 0;
  transition-duration: 0s;
  transition-delay: 0s;
}

.main-wrapper {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

main {
  margin: .25rem .5rem;
}

@media (width >= 640px) {
  main {
    margin: .5rem 1rem;
  }
}

@media (width >= 768px) {
  main {
    margin: 1rem 1.5rem;
  }
}

@media (width >= 1024px) {
  main {
    margin: 1rem 2rem;
  }
}

@media (width >= 1280px) {
  main {
    margin: 1.25rem 2.5rem;
  }
}

@media (width >= 1536px) {
  main {
    margin: 1.5rem 3rem;
  }
}

main {
  max-width: calc(65ch + 2 * var(--main-px)  + 2 * var(--main-p-mx));
  --tw-shadow: var(--tw-shadow-colored);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: rgba(var(--ctp-surface0), .6);
}

@media (width >= 1024px) {
  main {
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  }
}

@media (width >= 1536px) {
  main {
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgba(var(--ctp-surface0), .6);
  }
}

main {
  outline-width: 1px;
  outline-style: solid;
  outline-color: rgb(var(--ctp-surface1));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(var(--ctp-surface1), var(--tw-ring-opacity));
  --tw-ring-offset-width: 4px;
  --tw-ring-offset-color: rgba(var(--ctp-mantle), .6);
  --tw-bg-opacity: 1;
  background-color: rgba(var(--ctp-base), var(--tw-bg-opacity));
  padding-left: var(--main-px);
  padding-right: var(--main-px);
  padding-top: var(--main-py);
  padding-bottom: var(--main-py);
  display: flow-root;
}

main > :first-child {
  margin-top: .75rem;
}

@media (width >= 640px) {
  main > :first-child {
    margin-top: 1rem;
  }
}

@media (width >= 768px) {
  main > :first-child {
    margin-top: 1.5rem;
  }
}

@media (width >= 1024px) {
  main > :first-child {
    margin-top: 2rem;
  }
}

main > :last-child {
  margin-bottom: .75rem;
}

@media (width >= 640px) {
  main > :last-child {
    margin-bottom: 1rem;
  }
}

@media (width >= 768px) {
  main > :last-child {
    margin-bottom: 1.5rem;
  }
}

@media (width >= 1024px) {
  main > :last-child {
    margin-bottom: 2rem;
  }
}

main h1, main h2, main h3, main h4, main h5, main h6 {
  text-wrap: balance;
}

main h1 {
  width: fit-content;
  margin: 2rem auto 1.5rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

@media (width >= 640px) {
  main h1 {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  main h1 {
    font-size: 3.75rem;
    line-height: 1;
  }
}

main h1 {
  text-shadow: 1px 2px 4px rgb(var(--ctp-text), .6);
}

main h2 {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 640px) {
  main h2 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

main h3 {
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (width >= 640px) {
  main h3 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

main h4 {
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (width >= 640px) {
  main h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

main h5 {
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

@media (width >= 640px) {
  main h5 {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

main h6 {
  margin-top: .5rem;
  margin-bottom: .25rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (width >= 640px) {
  main h6 {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

main form, main p {
  margin-left: var(--main-p-mx);
  margin-right: var(--main-p-mx);
  margin-top: var(--main-p-my);
  margin-bottom: var(--main-p-my);
}

main p {
  text-wrap: pretty;
}

@media (width >= 640px) {
  main p {
    text-align: justify;
  }
}

.page > .footer-spacer {
  flex-grow: 1;
}

.page > footer {
  width: 100%;
  margin-bottom: .25rem;
}

@media (width >= 640px) {
  .page > footer {
    margin-bottom: .5rem;
  }
}

@media (width >= 768px) {
  .page > footer {
    margin-bottom: 1rem;
  }
}

@media (width >= 1024px) {
  .page > footer {
    margin-bottom: 1rem;
  }
}

@media (width >= 1280px) {
  .page > footer {
    margin-bottom: 1.25rem;
  }
}

@media (width >= 1536px) {
  .page > footer {
    margin-bottom: 1.5rem;
  }
}

.page > footer {
  justify-content: center;
  align-items: center;
  display: flex;
}

.page > footer .fa-icon > svg {
  width: 24px;
  height: 24px;
}

@media (width >= 1024px) {
  .page > footer .fa-icon > svg {
    width: 30px;
    height: 30px;
  }
}

@media (width >= 1280px) {
  .page > footer .fa-icon > svg {
    width: 32px;
    height: 32px;
  }
}

@media (width >= 1536px) {
  .page > footer .fa-icon > svg {
    width: 36px;
    height: 36px;
  }
}

.page > footer .fa-icon > svg {
  fill: rgb(var(--ctp-subtext0));
  stroke: rgb(var(--ctp-subtext0));
  transition-property: background-color, border-color, box-shadow, color, fill, opacity, outline, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.page > footer .fa-icon > svg:hover {
  fill: rgb(var(--ctp-sapphire));
  stroke: rgb(var(--ctp-sapphire));
}

.page > footer .fa-icon > svg:active {
  fill: rgb(var(--ctp-sky));
  stroke: rgb(var(--ctp-sky));
  transition-duration: 75ms;
}

.welcome {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: 3.75rem;
  line-height: 1;
}

@media (width >= 640px) {
  .welcome {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  .welcome {
    font-size: 6rem;
    line-height: 1;
  }
}

@media (width >= 1280px) {
  .welcome {
    font-size: 8rem;
    line-height: 1;
  }
}

.welcome {
  background: text linear-gradient(to top right, rgb(var(--ctp-maroon)), rgb(var(--ctp-yellow)) 50%, rgb(var(--ctp-peach)) 80%);
  color: #0000;
  text-shadow: none;
  font-family: MonteCarlo, cursive;
  font-weight: 500;
}

@media (width >= 768px) {
  .welcome-intro {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.welcome-intro {
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-subtext1), var(--tw-text-opacity));
}

form {
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: .5rem 1rem;
  display: grid;
}

form.virgin :-webkit-any(input, select, textarea):not(:focus-visible) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

form.virgin :is(input, select, textarea):not(:focus-visible) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

form.virgin .label-wrapper > .errors-spacer, form.virgin .input-wrapper > .errors {
  display: none;
}

form .label-wrapper, form .input-wrapper {
  grid-row: span 2 / span 2;
  grid-template-rows: subgrid;
  gap: 0;
  display: grid;
}

form .label-wrapper > label {
  align-self: center;
}

form .input-wrapper > .errors {
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-color: rgba(var(--ctp-maroon), .2);
  background-color: rgba(var(--ctp-maroon), .1);
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-maroon), var(--tw-text-opacity));
  border-radius: .125rem;
  align-items: baseline;
  gap: .5rem;
  margin-left: .375rem;
  margin-right: .375rem;
  padding: .5rem 1rem;
  font-family: Nunito Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.25rem;
  display: flex;
}

form .input-wrapper > .errors > .material-symbols-outlined {
  font-variation-settings: "FILL" 1, "opsz" 18;
  font-size: 18px;
  line-height: 1;
  position: relative;
  top: .2cap;
}

form .input-wrapper > .errors > ul > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  border-style: solid;
  border-color: rgba(var(--ctp-maroon), .2);
}

form .input-wrapper > .errors > ul > li {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

form > .actions {
  flex-direction: row-reverse;
  grid-column: span 2 / span 2;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  display: flex;
}

form > .status {
  border-width: 2px;
  border-color: rgba(var(--ctp-blue), .4);
  background-color: rgba(var(--ctp-blue), .2);
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-blue), var(--tw-text-opacity));
  border-radius: .375rem;
  grid-column: span 2 / span 2;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: .75rem;
  margin-bottom: .75rem;
  padding: 1rem;
  font-family: Nunito Sans, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375;
  display: flex;
}

form > .status > .material-symbols-outlined {
  font-variation-settings: "FILL" 1, "opsz" 30;
  font-size: 30px;
  line-height: 2.25rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

form > .status.submitting > .material-symbols-outlined {
  animation: 1s linear infinite spin;
}

form > .status.succeeded {
  border-color: rgba(var(--ctp-green), .4);
  background-color: rgba(var(--ctp-green), .2);
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-green), var(--tw-text-opacity));
}

form > .status:-webkit-any(.invalid, .failed) {
  border-color: rgba(var(--ctp-maroon), .4);
  background-color: rgba(var(--ctp-maroon), .2);
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-maroon), var(--tw-text-opacity));
}

form > .status:is(.invalid, .failed) {
  border-color: rgba(var(--ctp-maroon), .4);
  background-color: rgba(var(--ctp-maroon), .2);
  --tw-text-opacity: 1;
  color: rgba(var(--ctp-maroon), var(--tw-text-opacity));
}

form.contact > :has(textarea[name="body"]) {
  grid-column: span 2 / span 2;
  margin-top: .25rem;
  margin-bottom: .25rem;
}

form.contact > :has(textarea[name="body"]) label[for="body"] {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

form.contact > :has(textarea[name="body"]) textarea[name="body"] {
  width: 100%;
  height: 12cap;
}

form.contact button[type="submit"] > .label {
  margin-left: .25rem;
}

[data-elm-hot="true"] {
  height: inherit;
}
/*# sourceMappingURL=index.bae38049.css.map */
